.App {
  max-width: 900px;
  margin: 0 auto;
  padding: 5px;
}

.main {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  width: 800px;
  margin: 0 auto;
}    

.header {
  height: 80px;
  padding: 20px;
  box-sizing: border-box;

}

.logo_block {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.card {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  margin-bottom: 25px;
  box-shadow: 0 8px 24px rgba(149,157,165,.2);
  
}

.article {
  color: black;
  cursor: pointer;
  margin-bottom: 5px;
}

.article:hover {
  color: #2196F3;
}

.logo {
  height: 40px;
  cursor: pointer;
}

.logo_text {
  margin-left: 10px;
  font-weight: 500;
}

.logo_text:hover {
  color: #2196F3;
}

.step_page {
  background-color: white;
  padding: 50px;
}

.text {
  margin-bottom: 20px;
  font-size: 18px;
  white-space: pre-line;
}

img {
  max-width: 100%;
  height: auto;
  border: 1px solid lightgray;
}

.buttons {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

button {
  background-color: white;
  padding: 10px 20px;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;  
}

button:hover {
  box-shadow: 0 8px 24px rgba(149,157,165,.2);
}

.success {
  border-color: #1d5eff;
  background-color: #1d5eff;
  color: white;
  font-weight: 500;
}

.textarea, .input {
  width: 100%;
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 10px;
  font-size: 14px;
}

.checkbox {
  width: 20px;
}

.margin {
  margin-top: 20px;
}

.star {
  color: gray;
  font-size: 48px;
  cursor: pointer;
}

.selected {
  color: gold;
}



.info {
  border: 1px solid #ffe58f;
  padding: 20px;
  background-color: #fffbe6;
}

.important {
  border: 1px solid #ffe58f;
  margin-bottom: 20px;
  padding: 20px;
}

h4 {
  margin-top: 0;
  padding-top: 0;
}