/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700;900&family=Open+Sans:wght@300;500;700&family=Roboto:wght@300;500;700&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(8, 27, 75);
  font-weight: 300;
  font-size: 18px;
  background-color: #f5f5f5;
}

code, button {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
